import React from "react"
import styled from "@emotion/styled"
import { Grid, Label, List, PopupContent } from "semantic-ui-react"
import theme from "@styles/theme"
import mq from "@styles/mq"
import { H4, H5, H6 } from "@styles/Global.styles"

export const PrivatePageWrapper = styled.div`
  display: flex;
  flex: 1;
  background: rgb(79 116 189);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
  padding: 0;
  margin: 0;
  flex-direction: column;
  &:before {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    content: "";
    z-index: 0;
  }
`
export const NavListItemHeader = styled(List.Content)`
  font-weight: bold;
  font-size: 18px;
  color: ${theme.brand.colors.black};
`
export const NavListItem = styled(({ active, ...others }) => (
  <List.Item {...others} />
))`
  font-weight: ${props => (props.active ? "bold" : "")};
  color: ${props =>
    props.active ? "black !important" : theme.brand.colors.darkGrey};
  font-size: 18px;
`
export const NavListItemContent = styled(List.Content)`
  line-height: 32px !important;
`
export const UserManageHeader = styled.div`
  ${mq({
    padding: ["53px 20px", "53px 20px", "30px 60px 10px 60px"],
    textAlign: ["center", "left", "left"],
  })}
`
export const UserManageContent = styled.div`
  background-color: #f8f8f8;
  min-height: calc(100vh - 336px);
`
export const UserContentEmpty = styled.div`
  background-color: #f8f8f8;
  min-height: calc(100vh - 336px);
  display: flex;
`
export const UserManageContentArea = styled(Grid.Column)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`
export const UserManageColumn1 = styled(Grid.Column)`
  ${mq({
    padding: [
      "10px 10px 10px 10px !important",
      "10px 10px 10px 10px !important",
      "0 15px 0 60px !important",
    ],
  })}
`
export const UserManageWrapper = styled.div`
  padding: 0 30px 0 30px;
`
export const UserManageColumn2 = styled(Grid.Column)`
  ${mq({
    padding: [
      "10px 10px 10px 10px !important",
      "10px 10px 10px 10px !important",
      "0 60px 0 15px !important",
    ],
  })}
`
export const UserManageSectionHeader = styled(H4)`
  ${mq({
    paddingTop: ["30px", "30px", "60px"],
  })}
  padding-bottom: 20px;
`

export const NewListingTitle = styled.div`
  font-size: 36px;
  padding: 20px 0;
  line-height: 50px;
  font-weight: bold;
`
export const ListingTypeCardContainer = styled.div`
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  position: relative;
  padding: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.selected {
    &::before {
      content: "";
      color: transparent;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: solid 5px #3dae2b;
      border-radius: 12px;
    }
  }
`
export const ListingTypeCardHeading = styled(H5)`
  margin-bottom: 5px !important;
  ${mq({
    fontSize: ["16px !important", "16px !important", "20px !important"],
  })}
`
export const ListingTypePricingSection = styled.div`
  display: flex;
  padding: 14px 0;
`
export const ListingTypePeriod = styled.div`
  padding-left: 10px;
  line-height: 22px;
  color: ${theme.brand.colors.darkGrey};
`
export const ListingSection = styled.div`
  padding-top: 20px;
`

export const PriceInputLabel = styled(Label)`
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border-right: 0 !important;
  background-color: #eeeeee !important;
`
export const PriceInputText = styled.input`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
`
